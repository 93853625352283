export const MY_REPOS_URL = "https://api.github.com/users/Moamal-2000/repos";
export const WEBSITE_REPO_ID = 681199946;
export const MINIMUM_QUANTITY = 1;
export const MAXIMUM_QUANTITY = 1000;
export const UN_BUILT_PAGES = [
  "cancellations",
  "reviews",
  "faq",
  "terms",
  "privacy",
];
